<template>
    <div id="footerContent">
        <div class="logoSection">
            <router-link to="/" class="logoContainer">
                <img src="/images/paul_salazar.png" class="logo"/>
            </router-link>
        </div>
        <div class="footer-contact">
            <a class="phone" :href="'tel:'+telephone">
                {{telephone}}
            </a>
            <a class="email" :href="'mailto:'+email">
                {{email}}
            </a>
        </div>
        <div class="logoSection">
            <router-link to="/" class="logoContainer">
                <img src="/images/HH_logo.png" class="logo"/>
            </router-link>
            <router-link to="/" class="logoContainer">
                <img src="/images/forbes.png" class="logo2"/>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterContent",
    data(){
        return {
            email: "info@paulsalazargroup.com",
            telephone: "310.492.0737",
        }
    }
}
</script>

<style lang="scss" scoped>
.logoSection{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 1rem auto;
    .logoContainer{
        padding: 1.25rem 2rem;
        .logo{
            display: block;
            margin: 0 auto;
            width: 200px;
        }
        .logo2{
            display: block;
            margin: 0 auto;
            width: 160px;
        }
    }
}
.footerContent{
    text-align: center;
    text-transform: uppercase;
    padding-top: 2rem;
}

.footer-contact{
    text-align: center;
    text-transform: uppercase;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 50%;
    margin: 0 auto;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
    a{
        padding: 0.5rem 1rem;
        display:block;
        text-decoration: none;
        letter-spacing: 2px;
        font-weight: 500;
        color: #fff;
        &:hover{
            opacity: 0.75;
        }
        &.phone{
            font-size: 18px;
        }
        &.email{
            font-size: 15px;
        }
    }
}
@media (max-width: $mobile) {
    .logoSection{
        .logoContainer{
            width: 100%;
            padding: 1.5rem 1rem;
            text-align: center;
            &.leftLogo{
                border-right: none;
                text-align: center;
                .logo{
                    height: 40px;
                    width: auto;
                }
            }
            .logo{
                height: auto;
                width: 100%;
                max-width: 200px;
            }
            .logo2{
                height: auto;
                width: 100%;
                max-width: 160px;
            }
        }
    }
    .footerContent{
        padding-top: 1rem;
    }
    .footer-contact{
        width: 80%;
    }
}
</style>
