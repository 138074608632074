<template>
    <div class="subscribe-container">
        <div class="subscribe-inside">
            <h2>
                Newsletter Signup
            </h2>
            <form method="post" @submit.prevent="handleSubmit">
                <input type="hidden" name="form-name" value="email-signup"/>
                <div class="form-submission">
                    <div class="input-field">
                        <input type="email" name="email" id="email" placeholder="email@address.com" @input="ev => form.email = ev.target.value">
                        <input class="submit-button" :disabled="!checked" value="Subscribe" type="submit">
                    </div>
                    <div class="checkbox-field">
                        <input type="checkbox" id="agreement" name="agreement" v-model="checked">
                        <label for="agreement"> I would like to receive promotional emails.</label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "MailSignup",
    data(){
        return {
            checked: false,
            form: {
                email: ''
            },
        }
    },
    methods: {
        resetform(){
            this.form.email = '';
            this.checked = false;
        },
        handleSubmit() {
            this.axios({
                method: 'post',
                url: `https://api.sierrainteractivedev.com/leads`,
                data: {
                    firstName: "No",
                    lastName: "Name",
                    password: '3104920737',
                    email: this.form.email
                },
                headers: {
                    'Sierra-ApiKey': '1f24396c-ae08-493f-9c4e-7770a5dced44'
                }
            })
            .then(
                () =>{
                    this.$toast.success("Thank you for subscription", {
                        position: "top-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
            })
            .catch(
                () => {
                    this.$toast.error("Error submitting, please try again.", {
                        position: "top-center",
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }
            )
            this.resetform();
        }
    }
}
</script>

<style lang="scss" scoped>
.subscribe-container{
    padding-top: 2rem;
    background: black;
}
.subscribe-inside{
    background: #262626;
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 90%;
    color: #fff;
    text-align: center;
    h2{
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}
.form-submission{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.input-field{
    width: 400px;
    position: relative;
    margin-top: 20px;
    input[type=email]{
        width: 100%;
        height: 50px;
        font-size: 15px;
        letter-spacing: 2px;
        padding-left: 10px;
        color: #000;
        &::placeholder{
            color: #999;
        }
    }
    .submit-button{
        display: block;
        position: absolute;
        right: 10px;
        top: 7px;
        padding: 10px 10px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
        -webkit-appearance:none;
        border: 1px solid #ccc;
        &:enabled{
            color: #fff;
            background: #bf9b30;
            border: 1px solid #bf9b30;
            &:hover{
                cursor: pointer;
            }
        }
        &:disabled{
            &:hover{
                cursor: not-allowed;
            } 
        }
    }
}
.checkbox-field{
    width: 400px;
    margin-top: 20px;
    input {
        margin: 0;
        width: 16px;
        height: 16px;
    }
    label{
        vertical-align: text-top;
        font-size: 15px;
        letter-spacing: 2px;
        opacity: 0.5;
    }
}

@media (max-width: $mobile) { 
    .input-field{
        max-width: 80%;
        input[type=email]{
            font-size: 15px;
            letter-spacing: 1px;
            padding-left: 5px;
            color: #000;
            &::placeholder{
                color: #999;
            }
        }
        .submit-button{
            display: block;
            position: absolute;
            right: 5px;
            top: 7px;
            padding: 10px 5px;
            letter-spacing: 1px;
        }
    }
    .checkbox-field{
        width: 400px;
        max-width: 80%;
        margin-top: 20px;
        input {
            margin: 0;
            width: 16px;
            height: 16px;
        }
        label{
            vertical-align: text-top;
            font-size: 15px;
            letter-spacing: 1px;
            opacity: 0.5;
        }
    }
    .subscribe-inside{
        width: 100%;
    }

}
</style>
