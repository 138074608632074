<template>
    <div class="contact-form-container" id="inquire">
      <div class="title" data-aos="fade-up" data-aos-duration="800" >
        <h1>Let's Discover Together</h1>
      </div>
      <div class="description" data-aos="fade-up" data-aos-duration="800" >
        <p>
          Start your journey towards a luxurious lifestyle. Kindly fill in the form below and we'll reach out to you soon.
          For direct connection, please call 
          <a href="tel:310-492-0737" target="_blank" rel="noopener noreferrer"
            >310.492.0737</a
          >
          or email to
          <a
            href="mailto:info@paulsalazargroup.com"
            target="_blank"
            rel="noopener noreferrer"
            >info@paulsalazargroup.com</a
          >.
        </p>
      </div>
      <form 
        name="contact"
        method="post"
        @submit.prevent="submitForm">
        <div class="form-input-container" data-aos="fade-up" data-aos-duration="800" >
          <div class="left">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" name="name" v-model="data.firstName" />
            </div>
            <div class="form-group">
              <label for="name">Email *</label>
              <input
                required
                type="email"
                name="email"
                v-model="data.email" 
              />
            </div>
            <div class="form-group">
              <label for="name">Phone</label>
              <input
                type="text"
                name="phone"
                v-model="data.phone"
              />
            </div>
          </div>
          <div class="right">
            <div class="form-group">
              <label for="message">Message</label>
              <textarea name="message" id="message" cols="30" rows="10" v-model="data.note"/>
            </div>
          </div>
        </div>
        <button :class="{loading : loading}" :disabled="loading" type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  <script>

  const requestHeader = {
    'Sierra-ApiKey': '1f24396c-ae08-493f-9c4e-7770a5dced44'
  }

  export default {
    name: "ContactForm",
    data(){
      return{
        emailSubmitTo: 'fe767e723c7a601b52901b2e81db4b9d',
        data: {
            email: '',
            firstName: '',
            phone: '',
            note: '',
            password: '3104920737'
        },
        loading: false,
      }
    },
    methods: {
      encode (data) {
        return Object.keys(data)
          .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
      },
      submitForm(){
          this.loading = true;
          this.axios({
              method: 'post',
              url: `https://api.sierrainteractivedev.com/leads`,
              data: this.data,
              headers: requestHeader
          })
          .then(() =>{
            this.$router.push("thankyou");
          })
          .catch(
              () => {
                  this.$toast.error("Error submitting, please try again.", {
                      position: "top-center",
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: "button",
                      icon: true,
                      rtl: false
                  });
              }
          )
          this.loading = false;
      }
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  .contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #000;
    .title h1{
        font-weight: 100;
        letter-spacing: 2px;
        font-size: 65px;
        text-transform: uppercase;
        color: #fff;
    }
    .description > p {
      font-weight: 100;
      font-size: 18px;
      width: 70%;
      max-width: 875px;
      margin: 0 auto;
      color: #fff;
      opacity: 0.9;
      a {
        color: #bf9b30;
        text-decoration: none;
      }
    }
    .form-input-container {
      display: flex;
      text-align: left;
      margin-top: 80px;
      .left {
        width: 50%;
        margin-right: 40px;
      }
      .right {
        flex: 1;
      }
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        label {
          font-weight: 300;
          color: #fff;
          font-size: 26px;
          text-transform: uppercase;
          margin-bottom: 0;
        }
        input {
          font-family: 'Catamaran', sans-serif;
          font-size: 21px;
          font-weight: 100;
          width: 330px;
          border: none;
          border-bottom: 0.1px solid #fff;
          background: #000 !important;
          max-width: 90%;
          -webkit-appearance: none;
          border-radius: 0;
          outline: none;
          color: #fff;
          padding-left: 10px;
        }
        textarea {
          font-family: 'Catamaran', sans-serif;
          width: 400px;
          height: 255px;
          padding: 15px 15px;
          font-size: 21px;
          resize: none;
          max-width: 85%;
          border: 0.1px solid #fff;
          background: #000;
          outline: none;
          color: #fff;
        }
      }
    }
    button {
      width: 120px;
      height: 36px;
      border: none;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      background: #bf9b30;
      margin: 40px 0;
      font-size: 16px;
      cursor: pointer;
      &.loading{
        color: #aaa;
        background: #fff;
        cursor: none;
      }
    }
  }
  
  @media screen and (max-width: $tablet) {
    .contact-form-container {
      .form-input-container {
        .title{
          h1{
            margin: 0 20px;
            margin-bottom: 20px;
            margin-top: 60px;
          }
        }
        .description{
          margin: 10px 20px;
        }
        display: flex;
        text-align: left;
        margin-top: 80px;
        .left {
          width: 50%;
          margin-right: 40px;
        }
        .right {
          flex: 1;
        }
        .form-group {
          input {
            width: 300px;
          }
          textarea {
            width: 300px;
            max-width: 90%;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: $mobile) {
    form{
      width: 100%;
    }
    .contact-form-container {
      width: 100%;
      margin: 40px auto;
      .title{
        h1{
          margin: 0 20px;
          margin-bottom: 20px;
          margin-top: 60px;
        }
      }
      .description{
        margin: 10px 20px;
      }
      .form-input-container {
        width: 100%;
        flex-wrap: wrap;
        .left {
          width: 100%;
          margin-right: 0;
          margin-left: 0;
          padding-left: 20px;
        }
        .right{
          max-width: 100%;
          margin-left: 0;
          padding-left: 20px;
        }
        .form-group {
          margin-left: 0;
          input {
            width: 100%;
          }
          textarea {
            width: 100%;
            max-width: 90%;
          }
        }
  
      }
    }
  }
  </style>