<template>
    <div id="footerCredits">
        <p v-html="copyright"></p>
        <p style="display:inline">
            {{credits}}
        </p>
        <a href="https://uptowncreative.io/" target="_blank">Uptown Creative</a>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            copyright: "Copyright &copy " + d.getFullYear() + " Paul Salazar, Hilton & Hyland. CA DRE# 01763227.",
            credits: "Website created by",
        }
    },
}
</script>

<style lang="scss" scoped>
#footerCredits{
    padding: 2rem 0 2rem 0;
    text-align: center;
    p {
        font-weight: 500;
        margin: 0;
        padding: 0.25rem 0;
        color: #fff;
        opacity: 0.25;
        font-size: 12px;
        letter-spacing: 2px;
    }
    a{
        text-decoration: none;
        color: #fff;
        font-size: 12px;
        letter-spacing: 2px;
        font-weight: 500;
        opacity: 0.75;
        &:hover{
            opacity: 0.5;
        }
    }
}
@media (max-width: $mobile) { 
    #footerCredits{
        padding: 2rem;
    }
}
</style>